
import { Options, Vue } from 'vue-class-component'
import axios from '@/plugins/axios'
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'
import { highlight, languages } from 'prismjs'
import 'vue-prism-editor/dist/prismeditor.min.css'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css'

interface Result {
  data: any
  message: string
  name: string
  type: string
  status: boolean
  content: string
  files: any
}

@Options({
  components: { PrismEditor }
})
export default class TemplatePage extends Vue {
  name = 'Templates'
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  listDataPlatform: any = []
  formData: any = {
    type: '',
    platform: '',
    content: '',
    name: '',
    files: [],
  }
  data: any = new FormData()
  titleModal = ''
  dialogPlatform = false
  url = `${process.env.VUE_APP_BASE_URL}/`

  async created() {
    this.getListPlatform()
  }

  async getListPlatform() {
    let res: Result
    await this.$store.dispatch('setLoading', true, { root: true })
    res = await axios.get('admin/platform', {
      headers: this.headers
    })
    const listDataPlatform = [
      {
        platform: 'iomnic',
        name: 'Core Template',
        image: '',
      },
    ];
    if (res) {
      try {
        if (Array.isArray(res.data)) {
          res.data = res.data.map(item => {
            return {
              ...item,
              platform: item.type,
            }
          })
          listDataPlatform.push(...res.data);
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    this.listDataPlatform = listDataPlatform;
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  async dialogOpenModal(row: any, type: string) {
    this.titleModal = 'Update Template'
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result
    res = await axios.post('admin/template/detail', {
      type,
      platform: row.platform,
    },{
      headers: this.headers
    })
    if (!res || !res.status) {
      this.$message.warning(res.message);
      return;
    }

    await this.$store.dispatch('setLoading', false, { root: true })
    this.formData.name = row.name
    this.formData.platform = row.platform
    this.formData.type = type;
    this.formData.content = res.content;
    this.formData.files = res.files || [];
    this.dialogPlatform = true
  }

  highlighter(code: any) {
    return highlight(code, languages.js, 'javascript')
  }

  async viewOldContent (file: any) {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result
    res = await axios.post('admin/template/old-content', {
      Key: file.Key
    },{
      headers: this.headers
    })
    await this.$store.dispatch('setLoading', false, { root: true })
    if (!res || !res.status) {
      this.$message.warning(res.message);
      return;
    }
    const newTab = window.open('', "_blank") as Window;
    newTab.document.write(`<html><body><pre>${res.content}</pre></body></html>`);
    newTab.document.close();
  }

  async handleSave() {
    let res: Result
    res = await axios.post('admin/template/update', {
      type: this.formData.type,
      platform: this.formData.platform,
      content: this.formData.content,
    },{
      headers: this.headers
    })
    if (res && res.status) {
      this.$message.success(res.message)
      this.dialogPlatform = false
    } else {
      this.$message.warning(res.message)
    }
  }
}
